import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { AlcoholIcon } from "../../components/icons/AlcoholIcon";
import { BreakfastIcon } from "../../components/icons/BreakfastIcon";
import { DessertIcon } from "../../components/icons/DessertIcon";
import { DrinksIcon } from "../../components/icons/DrinksIcon";
import { HotDrinksIcon } from "../../components/icons/HotDrinksIcon";
import { MainsIcon } from "../../components/icons/MainsIcon";
import { SaladIcon } from "../../components/icons/SaladIcon";
import { SoupIcon } from "../../components/icons/SoupIcon";
import { StarterIcon } from "../../components/icons/StarterIcon";
import {
  DineInCategories,
  DineInSection,
  Maybe,
  useAddDineInSectionsMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { MakiraIcon } from "../../components/icons/makiraicon";
import { TwentyIcon } from "../../components/icons/twenty-forthIcon";
import { JonesIcon } from "../../components/icons/jonesIcon";
import { BenjarongIcon } from "../../components/icons/benjarongicon";
import { DiningIcon } from "../../components/icons/AlldaydiningIcon";
import useEdible from "../Dine-In-Menu/hotel-edible-hook";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { CKTextEditor } from "../../components/Editor/CKEditor";
import { useAuthProvider } from "../../core/authContext";
import { startCase, camelCase } from "lodash";
interface prop {
  editData: DineInSection | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
  categories: Array<DineInCategories | null> | null | undefined;
  isCustomized: boolean;
  setIsCustomized: (v: boolean) => void;
}

const SectionForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
  categories,
  isCustomized,
  setIsCustomized,
}: prop) => {
  const { MenuProps } = useEdible();
  const [image, setImage] = React.useState<any>("");
  const [uploadIcon, setUploadIcon] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);
  const { getUser } = useAuthProvider();
  const property = getUser();
  const icons = [
    {
      name: "AlcoholIcon",
      icon: <AlcoholIcon />,
    },
    {
      name: "BreakfastIcon",
      icon: <BreakfastIcon />,
    },
    {
      name: "SaladIcon",
      icon: <SaladIcon />,
    },
    {
      name: "DessertIcon",
      icon: <DessertIcon />,
    },
    {
      name: "DrinksIcon",
      icon: <DrinksIcon />,
    },
    {
      name: "HotDrinksIcon",
      icon: <HotDrinksIcon />,
    },
    {
      name: "SoupIcon",
      icon: <SoupIcon />,
    },
    {
      name: "StarterIcon",
      icon: <StarterIcon />,
    },
    {
      name: "MainsIcon",
      icon: <MainsIcon />,
    },
    {
      name: "MakiraIcon",
      icon: <MakiraIcon />,
    },
    {
      name: "TwentyIcon",
      icon: <TwentyIcon />,
    },
    {
      name: "BenjarongIcon",
      icon: <BenjarongIcon />,
    },
    {
      name: "DiningIcon",
      icon: <DiningIcon />,
    },
    {
      name: "JonesIcon",
      icon: <JonesIcon />,
    },
  ];

  const setDate = (time: Maybe<string> | undefined) => {
    if (typeof time === "string") {
      const date = new Date(
        `04/28/2023 ${time.split(":")[0]}:${time.split(":")[1]}:00 UTC`
      );
      return dayjs(date.toString());
    }
    return time;
  };
  const {
    register,
    handleSubmit,
    // formState: { isDirty },
    setValue,
    reset: SectionReset,
    watch: SectionWatch,
    control,
  } = useForm<DineInSection>({
    defaultValues: {
      name: editData?.name || "",
      icon: editData?.icon || "",
      desc: editData?.desc || "",
      default_category: editData?.default_category || "",
      time_based: editData?.time_based || false,
      message: editData?.message || "",
      uploaded_icon: editData?.uploaded_icon || "",
    },
  });
  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    SectionReset(
      editData || {
        name: "",
        icon: "",
        desc: "",
        default_category: "",
        message: "",
        uploaded_icon: "",
      }
    );
  }, [editData, SectionReset, open]);
  const [upsertSections] = useAddDineInSectionsMutation();

  const onSubmit = async (data: DineInSection) => {
    const uploaded_icon =
      data.icon !== "Customized"
        ? ""
        : image
        ? uploadIcon
        : data?.uploaded_icon;
    try {
      const res = await upsertSections({
        variables: {
          dineInSectionInput: {
            id: editData?.id || "",
            name: data.name,
            icon: data.icon,
            property_id: propertyId,
            desc: data?.desc,
            sequence: data?.sequence,
            default_category: data?.default_category,
            time_based: data?.time_based,
            message: data?.message,
            uploaded_icon: uploaded_icon,
            is_Activated: true,
            start_time:
              typeof data.start_time === "string"
                ? editData?.start_time
                : `${new Date(data.start_time || "").getUTCHours()}:${new Date(
                    data.start_time || ""
                  ).getUTCMinutes()}`,
            end_time:
              typeof data.end_time === "string"
                ? editData?.end_time
                : `${new Date(data.end_time || "").getUTCHours()}:${new Date(
                    data.end_time || ""
                  ).getUTCMinutes()}`,
          },
        },
      });
      if (res.data?.upsertDineInSection) {
        setOpen(false);
        setImage("");
        swal({
          text: "Section Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadIcon(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };
  const ALL_MENU = "ALL_MENU";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={handleEditClose}>
        <DialogTitle
          color={"#fff"}
          bgcolor={(theme) => theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">
              {editData ? "Update Section" : "Add New Section"}
            </Typography>
            <IconButton color={"inherit"} onClick={handleEditClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={2}>
              {/* Category form*/}
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Enter Section Name"
                  {...register("name", {
                    maxLength: 60,
                    required: "Name is required!",
                  })}
                />
              </Grid>
              <Grid item md={12}>
                <CKTextEditor
                  initialValue={editData?.desc || ""}
                  propertyId={property.id}
                  fieldName="desc"
                  setValue={setValue}
                  getUploadSignedURL={getUploadSignedURL}
                  setDisable={setIsDisable}
                />
              </Grid>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={SectionWatch("time_based") || false}
                      {...register("time_based")}
                    />
                  }
                  label="Time Based"
                />
              </Grid>
              {SectionWatch("time_based") && (
                <Grid item md={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <Controller
                        name="start_time"
                        render={({ field: { onChange, value } }) => (
                          <TimeField
                            label="Start Time"
                            value={setDate(value)}
                            onChange={(newValue) => onChange(newValue)}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Controller
                        name="end_time"
                        render={({ field: { onChange, value } }) => (
                          <TimeField
                            label="End Time"
                            value={setDate(value)}
                            onChange={(newValue) => onChange(newValue)}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Stack>
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label="Message To Guest"
                    {...register("message", {
                      maxLength: 60,
                    })}
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Icon
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("icon", {})}
                    label="Section Icon"
                    defaultValue={editData?.icon}
                    onChange={(e) => {
                      const selectedIcon = e.target.value as string;
                      setIsCustomized(selectedIcon === "Customized");
                    }}
                  >
                    {icons.map((ic, index) => (
                      <MenuItem key={ic.name} value={ic.name}>
                        <ListItemIcon>{ic.icon}</ListItemIcon>
                        {ic.name}
                      </MenuItem>
                    ))}
                    <MenuItem value="Customized">Customized</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ backgroundColor: "white", padding: "0 5px" }}
                  >
                    Select Default Category
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("default_category", {
                      required: "Category is required",
                    })}
                    label="Category"
                    defaultValue={editData?.default_category}
                  >
                    {categories?.map((category, index) => (
                      <MenuItem key={index} value={category?.name}>
                        {category?.name}
                      </MenuItem>
                    ))}
                    <MenuItem key={ALL_MENU} value={ALL_MENU}>
                      {startCase(camelCase(ALL_MENU))}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <Stack direction="row" justifyContent="center">
                  <Typography
                    color="text.secondary"
                    gutterBottom
                    align="center"
                  >
                    ------------OR------------
                  </Typography>
                </Stack>
              </Grid>
              {isCustomized && (
                <Grid item md={12}>
                  <Typography color="text.secondary" gutterBottom>
                    Upload Icon
                  </Typography>
                  {loadingImage ? (
                    <Box
                      sx={{
                        height: 100,
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "60px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    (editData?.uploaded_icon || image) && (
                      <Box>
                        <img
                          alt="Service"
                          width={"100px"}
                          src={image ? image : editData?.uploaded_icon}
                        />

                        {(uploadIcon || image) && (
                          <Box
                            component={IconButton}
                            onClick={() => setImage("")}
                          >
                            <Close />
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                  <Button variant="outlined">
                    <label>
                      {editData ? "Update Icon" : "Add icon"}
                      <Input
                        type="file"
                        sx={{ display: "none", cursor: "pointer" }}
                        onChange={handleIcon}
                      />
                    </label>
                  </Button>
                </Grid>
              )}
            </Grid>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={isDisable}
                loading={false}
                sx={{
                  margin: "auto",
                  marginTop: "25px",
                }}
              >
                {editData ? "Update Data" : "Submit Data"}
              </LoadingButton>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default SectionForm;
