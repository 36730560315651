import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Italic,
    Link,
    List,
    Paragraph,
    Table,
    Undo,
    Image,
    ImageUpload,
    ImageUploadUI,
    ImageResize,
    ImageStyle,
    ImageCaption,
    ImageToolbar,
    Highlight,
    TableToolbar,
    Alignment,
    BlockQuote,
    FileRepository,
    Base64UploadAdapter,
    FontSize,
    FontFamily,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import UploadAdapter from './MyUploadAdapter';
interface Props {
    initialValue: string;
    setValue?: any;
    fieldName: string;
    propertyId: string;
    getUploadSignedURL: (args: { variables: any }) => Promise<any>;
    setDisable?: any;
    setEditData?: any;
    editData?: any;
}
export const CKTextEditor: React.FC<Props> = ({ initialValue, setValue, fieldName, propertyId, getUploadSignedURL, setDisable, setEditData, editData }) => {
    const _onChange = (event: any, editor: any) => {
        if (setEditData) {
            setEditData({ ...editData, desc: editor.getData() })
        }
        if(setValue) {
            setValue(fieldName, editor.getData());
        }
        if (setDisable) {
            setDisable(false);
        }
    }

    const _onBlur = (event: any, editor: any) => {
        console.log('Blur.');
    }

    const _onFocus = (event: any, editor: any) => {
        console.log('_onFocus.');
    }
    const CustomUploadAdapterPlugin = (editor: any) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return new UploadAdapter(loader, setValue, fieldName, propertyId, getUploadSignedURL);
        };
    }
    return (
        <CKEditor
            editor={ClassicEditor as any}
            config={{
                plugins: [Base64UploadAdapter, FontSize, FontFamily, Essentials, Highlight, Table, TableToolbar, Link, Paragraph, Alignment, Heading, Image, Bold, ImageResize, Italic, ImageUpload, ImageUploadUI, FileRepository, ImageToolbar, ImageStyle, ImageCaption, BlockQuote, List, Undo],
                toolbar: ['heading', '|', 'bold', 'italic', '|', 'fontSize', 'fontFamily', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', 'link', 'blockQuote', '|', 'undo', 'redo', 'highlight'],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                    ]
                },
                ckfinder: {
                    uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },

                image: {
                    toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
                    resizeUnit: '%',
                    styles: [
                        'full',
                        'alignLeft',
                        'alignRight'
                    ]

                },
                alignment: {
                    options: ['left', 'right', 'center', 'justify']
                },
                highlight: {
                    options: [
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'var(--ck-highlight-pen-red)',
                            type: 'pen'
                        },
                        {
                            model: 'greenPen',
                            class: 'pen-green',
                            title: 'Green pen',
                            color: 'var(--ck-highlight-pen-green)',
                            type: 'pen'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: 'var(--ck-highlight-marker-yellow)',
                            type: 'marker'
                        },
                        {
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'var(--ck-highlight-marker-green)',
                            type: 'marker'
                        },
                        {
                            model: 'pinkMarker',
                            class: 'marker-pink',
                            title: 'Pink marker',
                            color: 'var(--ck-highlight-marker-pink)',
                            type: 'marker'
                        },
                        {
                            model: 'blueMarker',
                            class: 'marker-blue',
                            title: 'Blue marker',
                            color: 'var(--ck-highlight-marker-blue)',
                            type: 'marker'
                        },
                    ]
                },
                fontSize: {
                    options: [
                        '8px',
                        '9px',
                        '10px',
                        '11px',
                        '12px',
                        '14px',
                        '16px', // Custom sizes
                        '18px',
                        '20px',
                        '24px',
                        '28px',
                        '32px',
                        '36px',
                        '40px'
                    ],
                    supportAllValues: true, // Allow custom values
                },
                fontFamily: {
                    options: [
                        'default',
                        'Arial, Helvetica, sans-serif',
                        'Courier New, Courier, monospace',
                        'Georgia, serif',
                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                        'Tahoma, Geneva, sans-serif',
                        'Times New Roman, Times, serif',
                        'Trebuchet MS, Helvetica, sans-serif',
                        'Verdana, Geneva, sans-serif',
                    ],
                    supportAllValues: true, // Allow custom values
                },
            } as any}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            onReady={(editor: any) => { CustomUploadAdapterPlugin(editor); }}
            data={initialValue}
        />
    );
}
