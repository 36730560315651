import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  //   Button,
  //   CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  //   Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import {
  EMenu,
  AuthMethod,
  useAddEMenuMutation,
  useGetDepartmentsQuery,
} from "../../generated/graphql";
import UiSwitch from "../../components/toggleButton/UiSwitch";

interface prop {
  editData: EMenu | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}

const EMenuForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  //   const [image, setImage] = React.useState<any>("");
  //   const [uploadIcon, setUploadIcon] = React.useState<any>("");
  //   const [isDisable, setIsDisable] = React.useState(true);
  //   React.useEffect(() => {
  //     if (!open) {
  //       setImage("");
  //       setUploadIcon("");
  //     }
  //   }, [open]);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: EMenuReset,
    watch,
    setValue,
  } = useForm<EMenu>({
    defaultValues: {
      name: editData?.name || "",
      interactive: editData?.interactive || false,
      department_id: editData?.department_id || 0,
      auth_method: editData?.auth_method || AuthMethod.Email,
      email: editData?.email || "",
    },
  });

  React.useEffect(() => {
    EMenuReset(
      editData || {
        name: "",
        interactive: false,
        department_id: 0,
        auth_method: null,
        email: "",
      }
    );
  }, [editData, EMenuReset, open]);

  const [upsertEMenu] = useAddEMenuMutation();
  const { data: departments } = useGetDepartmentsQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: propertyId,
    },
  });

  const onSubmit = async (data: EMenu) => {
    try {
      const res = await upsertEMenu({
        variables: {
          eMenu: {
            id: editData?.id || null,
            name: data.name,
            property_id: propertyId,
            interactive: data.interactive,
            department_id: data.department_id,
            auth_method: data.auth_method,
            email: data.email,
          },
        },
      });
      if (res.data?.upsertEMenu) {
        setOpen(false);
        // setImage("");
        swal({
          text: "E-Menu Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleAuthMethodChange = (method: AuthMethod) => {
    const currentMethod = watch("auth_method");

    switch (method) {
      case AuthMethod.Room:
        // If Room is selected, deselect everything else
        setValue(
          "auth_method",
          currentMethod === AuthMethod.Room ? null : AuthMethod.Room
        );
        break;

      case AuthMethod.Email:
        if (currentMethod === AuthMethod.Room) {
          // Switch from Room to Email
          setValue("auth_method", AuthMethod.Email);
        } else if (
          currentMethod === AuthMethod.Phone ||
          currentMethod === AuthMethod.Combo
        ) {
          // Toggle between Phone + Email (Combo) or just Phone
          setValue(
            "auth_method",
            currentMethod === AuthMethod.Combo
              ? AuthMethod.Phone
              : AuthMethod.Combo
          );
        } else {
          // Toggle Email
          setValue(
            "auth_method",
            currentMethod === AuthMethod.Email ? null : AuthMethod.Email
          );
        }
        break;

      case AuthMethod.Phone:
        if (currentMethod === AuthMethod.Room) {
          // Switch from Room to Phone
          setValue("auth_method", AuthMethod.Phone);
        } else if (
          currentMethod === AuthMethod.Email ||
          currentMethod === AuthMethod.Combo
        ) {
          // Toggle between Email + Phone (Combo) or just Email
          setValue(
            "auth_method",
            currentMethod === AuthMethod.Combo
              ? AuthMethod.Email
              : AuthMethod.Combo
          );
        } else {
          // Toggle Phone
          setValue(
            "auth_method",
            currentMethod === AuthMethod.Phone ? null : AuthMethod.Phone
          );
        }
        break;

      default:
        break;
    }
  };

  //     if (e.target.files != null) {
  //       setImage(URL.createObjectURL(e.target.files[0]));

  //       const localFile = e.target.files[0];
  //       const filename = localFile.name;
  //       const fileType = localFile.type;
  //       const extension = filename.split(".")[1];
  //       const propertyID = propertyId;
  //       try {
  //         const { data: awsData } = await getUploadSignedURL({
  //           variables: {
  //             fileType,
  //             extension,
  //             propertyID,
  //           },
  //         });

  //         const {
  //           getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
  //         } = awsData as any;

  //         const picture = await fetch(URL.createObjectURL(e.target.files[0]));
  //         const pictureBlob = await picture.blob();
  //         const file = new File([pictureBlob], filename);

  //         await fetch(presigned_upload_url, {
  //           method: "PUT",
  //           body: file,
  //           headers: {
  //             "Content-Type": fileType,
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         });
  //         setUploadIcon(uploadedImageURL);
  //         setIsDisable(false);
  //       } catch (error) {
  //         swal({
  //           text: `${error}`,
  //           icon: "error",
  //         });
  //       }
  //     }
  //   };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update E-Menu" : "Add New E-Menu"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Sub Property form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter E-Menu Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <RadioGroup
                value={watch("interactive", false)}
                onChange={(e) =>
                  setValue("interactive", e.target.value === "true")
                }
                row
              >
                <FormControlLabel
                  value={false}
                  control={<Radio checked={watch("interactive") === false} />}
                  label="Static"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio checked={watch("interactive") === true} />}
                  label="Interactive"
                />
              </RadioGroup>
            </Grid>
            {watch("interactive") && (
              <>
                <Grid item md={12}>
                  <Stack spacing={2} alignItems={"flex-start"}>
                    <Typography color="text.secondary" gutterBottom>
                      Authentication Method
                    </Typography>
                    <UiSwitch
                      checked={watch("auth_method") === AuthMethod.Room}
                      label={"Room Number"}
                      color={(theme) => theme.palette.info.main}
                      onChange={() => handleAuthMethodChange(AuthMethod.Room)}
                    />
                    <UiSwitch
                      checked={
                        watch("auth_method") === AuthMethod.Email ||
                        watch("auth_method") === AuthMethod.Combo
                      }
                      label={"Email"}
                      color={(theme) => theme.palette.info.main}
                      onChange={() => handleAuthMethodChange(AuthMethod.Email)}
                    />
                    <UiSwitch
                      checked={
                        watch("auth_method") === AuthMethod.Phone ||
                        watch("auth_method") === AuthMethod.Combo
                      }
                      label={"Mobile Number"}
                      color={(theme) => theme.palette.info.main}
                      onChange={() => handleAuthMethodChange(AuthMethod.Phone)}
                    />
                  </Stack>
                </Grid>
                <Grid item md={12}>
                  <Typography color="text.secondary" gutterBottom>
                    Department
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ backgroundColor: "white", padding: "0 5px" }}
                    >
                      Select Department
                    </InputLabel>
                    <Select
                      required
                      value={watch("department_id") || 0}
                      onChange={(e) =>
                        setValue("department_id", Number(e.target.value))
                      }
                      label="Category"
                    >
                      <MenuItem value={0}>Select a category</MenuItem>
                      {departments?.getDepartments?.map((department, index) => (
                        <MenuItem key={index} value={department?.id || 0}>
                          {department?.department}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} mb={2}>
                  <Typography color="text.secondary" gutterBottom>
                    Notification Method
                  </Typography>
                  <TextField
                    fullWidth
                    label={"Enter Email ID"}
                    {...register("email", {
                      maxLength: 60,
                      // required: "Name is required!",
                    })}
                  />
                </Grid>
              </>
            )}
            {/* <Grid item md={12}>
            <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || image) && (
                  <Box>
                    <img
                      alt="Room"
                      width={"250px"}
                      src={image ? image : editData?.img}
                    />

                    {image && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid> */}
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EMenuForm;
