import React from 'react';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Sidebar from './sidebar';
import './layout.css';

const drawerWidth = 280;

type Props = {
  children?: React.ReactNode;
};
const Layout = ({ children }: Props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <div style={{ flexDirection: "row", display: "flex", height: "100vh", width: "100%" }}>
        <Sidebar
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <div style={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              padding: "24px 12px 12px 16px",
              overflow: "auto",
              width: "100%",
              scrollbarWidth: "none",
            }}>
            <Box
              component="main"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }}
            >
              <Toolbar />
              {children}
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default Layout;