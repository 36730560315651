class MyUploadAdapter {
  private loader: any;
  private setValue: any;
  private fieldName: string;
  private propertyId: string;
  private getUploadSignedURL: any;

  constructor(loader: any, setValue: any, fieldName: string, propertyId: string, getUploadSignedURL: any) {
    this.loader = loader;
    this.setValue = setValue;
    this.fieldName = fieldName;
    this.propertyId = propertyId;
    this.getUploadSignedURL = getUploadSignedURL;
  }

  async upload() {
    const file = await this.loader.file;
    const fileType = file.type;
    const filename = file.name;
    const extension = filename.split('.').pop();

    try {
      const { data } = await this.getUploadSignedURL({
        variables: { fileType, extension, propertyID: this.propertyId },
      });

      const {
        getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
      } = data;

      await fetch(presigned_upload_url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': fileType,
        },
      });

      return { default: uploadedImageURL };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  abort() {
    console.log('Upload aborted.');
  }
}

export default MyUploadAdapter;
