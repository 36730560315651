import { Add, EditOutlined } from "@mui/icons-material";
import { Grid, Typography, IconButton, Button, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import useSection from "./dine-in-section-hook";
import CategoryForm from "./Dine-in-Sections-form";
import Loader from "../../components/Loader";
import useCategoryList from "../Dine-In-Categories/dine-in-category-hooks";
import {
  useDraggable,
  Draggable,
  useDraggableContext,
  moveItems,
} from "react-tiny-dnd";
import { useEffect, useRef, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useAutoScroll } from "react-tiny-autoscroll";
import UiSwitch from "../../components/toggleButton/UiSwitch";

function convertUtcToLocalTime(utcTimeString: string) {
  if (
    !utcTimeString ||
    isNaN(parseInt(utcTimeString)) ||
    isNaN(parseInt(utcTimeString))
  ) {
    return utcTimeString;
  }
  const [hours, minutes] = utcTimeString.split(":");
  const utcDate = new Date();
  utcDate.setUTCHours(parseInt(hours));
  utcDate.setUTCMinutes(parseInt(minutes));
  const localTimeString = utcDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return localTimeString;
}

const Item = ({
  id,
  name,
  default_category,
  sequence,
  desc,
  start_time,
  end_time,
  listeners,
  isDragging,
  handleDelete,
  handleClickEditOpen,
  is_Activated,
  onToggle,
}: {
  id: any;
  name: any;
  default_category: any;
  sequence: any;
  desc: any;
  listeners: any;
  isDragging: any;
  handleDelete: any;
  start_time: any;
  end_time: any;
  handleClickEditOpen: any;
  is_Activated: boolean;
  onToggle: any;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {name}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {default_category}
      </div>
      <div
        style={{
          display: "flex",
          flex: 3,
          alignItems: "center",
        }}
      >
        {desc?.toString().startsWith("<") ? (
          <div
            contentEditable="false"
            dangerouslySetInnerHTML={{ __html: desc?.toString() || "" }}
          ></div>
        ) : (
          <Typography sx={{ color: "text.secondary" }}>{desc}</Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {start_time}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {end_time}
      </div>

      <UiSwitch
        checked={is_Activated != null ? is_Activated : true}
        onChange={() => onToggle()}
        label=""
      />
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  handleDelete,
  handleClickEditOpen,
  onToggle,
}: {
  index: any;
  context: any;
  item: any;
  handleDelete: any;
  handleClickEditOpen: any;
  onToggle: any;
}) => {
  const {
    listeners, // Handler listeners can be passed to Draggable component as well
    isDragging,
  } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.name}
        default_category={item.default_category}
        desc={item.desc}
        start_time={convertUtcToLocalTime(item.start_time)}
        end_time={convertUtcToLocalTime(item.end_time)}
        listeners={listeners}
        isDragging={isDragging}
        is_Activated={item.is_Activated}
        handleDelete={() => {
          handleDelete?.(item.id);
        }}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
        onToggle={() => onToggle(item)}
      />
    </Draggable>
  );
};

const HotelSections = () => {
  const {
    Sections,
    handleClickEditOpen,
    editData,
    handleEditClose,
    open,
    propertyId,
    refetchSection,
    setOpen,
    loadingCategories,
    handleDelete,
    onSave,
    onToggleSection,
    isCustomized,
    setIsCustomized,
  } = useSection();
  const { Categories } = useCategoryList();
  const [items, setItems] = useState<any[]>([]);
  useEffect(() => {
    if (Sections) {
      setItems(
        [...(Sections || [])].sort(
          (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
        )
      );
    }
  }, [Sections]);

  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });
  if (loadingCategories) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Sections Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Sections
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Box
          ref={containerRef}
          sx={{
            border: "1px solid #ddd",
            overflowY: "auto",
            borderRadius: "4px",
            m: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              borderBottom: "1px solid #eee",
            }}
            style={{ height: "initial", opacity: 1, backgroundColor: "white" }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Sequence"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1.8,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Section"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 2,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Default Category"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 2.5,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Description"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                fontWeight: "bold",
              }}
            >
              {"Start Time"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"End Time"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "0.5",
                alignItems: "center",
                fontWeight: "bold",
              }}
            ></div>
          </Box>
          {items?.map((item: any, i: any) => (
            <DraggableItem
              context={context}
              key={item.id}
              index={i}
              item={item}
              handleDelete={handleDelete}
              handleClickEditOpen={handleClickEditOpen}
              onToggle={onToggleSection}
            />
          ))}
        </Box>
        {/* Edit Dialog */}
        <CategoryForm
          editData={editData}
          categories={Categories}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={propertyId}
          refetch={refetchSection}
          setOpen={setOpen}
          isCustomized={isCustomized}
          setIsCustomized={setIsCustomized}
        />
      </Grid>
    </>
  );
};

export default HotelSections;
